import React from 'react'

const Admin = () => {
    let content = null; 

    async function getStats() {    
        const endpoint = '/COMP4537/termproject/API/v1/admin/stats'
        const devURL = `http://localhost:3000/${endpoint}`;
        const prodURL = `https://clintonfernandes.ca/${endpoint}`
    
        const URL = prodURL;
    
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJjbGludG9uZmVybmFuZGVzLmNhIiwiaWF0IjoxNjE3MTYwNTY1fQ.e64XGL7yjA2cFzLLtxp6lO8v5Yn5eudcRY2opVtGCtI');
    
        const body = {
            password: "thisQuizIsForMark"
        };
    
        const initObject = {
            method: "POST",
            headers: headers,
            body:JSON.stringify(body)
        };
    
        let res = await fetch(URL, initObject);
    
        if (res.ok) {
            let info = await res.json();
            console.log(info);
            content = res.json; 
        }
    }
    
    if(content) {

        {content.map((item, i) => (
            <div key={`${item.id}table`}> 
               ${endpointData.method}   | ${endpointData.endpoint}  |  ${endpointData.hits}
            </div>
       ))} 
    }

    
    return (
        <div>
            {content}
        </div>
    )
}

export default Admin; 