import React from 'react';
import styled from 'styled-components'; 
import { HomeConatiner } from '../Components'; 
import {Button } from '../GlobalStyle';


const HomePage = () => {
    return (
        <>
            <HomeConatiner />
        </>
    )
}

export default HomePage; 